import React from 'react'
import { Helmet } from 'react-helmet'

export default ({ children }) => (
  <Helmet>
    {children}
    <script src={"/dataLayer.js"} defer />

  </Helmet>
)
